import React from 'react';
import './FooterText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="recommend-footer">
        <div className="recommend-footer__header">
            <FormattedMessage id="recommend.footer.header" />
        </div>
        <div className="recommend-footer__text">
            <FormattedMessage id="recommend.footer.text" />
        </div>
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/recommend-leaf--375.png"
            />
            <img
                src="/static/images/recommend-leaf.png"
                alt=""
                className="recommend-footer__leaf"
            />
        </picture>
    </div>
);
