import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => {
    return (
        <div className="protect-eyes">
            <HeaderText/>
            <div className="protect-eyes-items">
                <Item
                    large
                    logo="/static/svg/protect-eyes-eye.svg"
                    header={<FormattedMessage id="protect.eyes.item.header.left" />}
                >
                    <div 
                        className="protect-eyes-item-left-text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'protect.eyes.item.left'})}}                
                    />
                </Item>
                <Item
                    xl
                    logo="/static/svg/protect-eyes-brain.svg"
                    header={<FormattedMessage id="protect.eyes.item.header.center" />}
                >
                    <div className="protect-eyes-item-center-text">
                        <FormattedMessage id="protect.eyes.item.center" />
                    </div>
                </Item>
                <Item
                    logo="/static/svg/protect-eyes-pill.svg"
                    header={<FormattedMessage id="protect.eyes.item.header.right" />}
                >
                    <div className="protect-eyes-item-right-text">
                        <FormattedMessage id="protect.eyes.item.right" />
                    </div>
                </Item>
            </div>
        </div>
    );
})