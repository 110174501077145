import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/narvid/malu">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="http://www.benu.ee">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/tooted?brand=GINKGO+COMPLEX">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/vitamiinid-ja-toidulisandid?page=1&per_page=96&brand%5B0%5D=GINKGO%20COMPLEX">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/tooted?brand=GINKGO+COMPLEX">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
