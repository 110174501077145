import React from 'react';
import HeaderText from "./HeaderText";
import FooterText from "./FooterText";
import BuyNowButton from '../../common/buy-now-button'
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="recommend" id="recommend">
        <HeaderText/>
        <div  className="recommend-content">
            <div  className="recommend-image">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/recommend-dr--375.png"
                    />
                    <img 
                        className="recommend-image--img"
                        alt=""
                        src="/static/images/recommend-dr.png"
                    />
                </picture>
            </div>
            <div  className="recommend-bottle">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/recommend-bottle--375.png"
                    />
                    <img 
                        className="recommend-bottle--img"
                        alt=""
                        src="/static/images/recommend-bottle.png"
                    />
                </picture>
            </div>
            <span className="recommend-buy-now__button">
                <BuyNowButton />
            </span>
            <div className="recommend-items">
                <Item
                    logo="/static/images/recommend-food.png"
                    text ={<FormattedMessage id="recommend.food" />}
                >     
                </Item>
                <Item
                    right
                    logo="/static/images/recommend-exercise.png"
                    text={<FormattedMessage id="recommend.exercise" />}
                >     
                </Item>
                <Item
                    logo="/static/images/recommend-smoking.png"
                    text={<FormattedMessage id="recommend.smoking" />}
                >   
                </Item>
                <Item
                    right
                    logo="/static/images/recommend-bluelight.png"
                    text={<FormattedMessage id="recommend.bluelight" />}
                >    
                </Item>
                <Item
                    logo="/static/images/recommend-greens.png"
                    text={<FormattedMessage id="recommend.greens" />}
                >    
                </Item>
            </div>
        </div>
        <FooterText/>
    </div>
);