import React from 'react';
import Item from './Item';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'danger__header': true,
        'danger__header--ru': locale === 'ru'
    });
    return (
        <div className="danger" id="danger">
            <div className={classes}>
                <FormattedMessage id="danger.header" />
            </div>
            <div className="danger__items">
                <Item
                    age
                    url="/static/svg/danger-age.svg"
                    text={<FormattedMessage id="danger.age" />}
                />
                <Item
                    weight
                    url="/static/svg/danger-weight.svg"
                    text={<FormattedMessage id="danger.weight" />}
                />
                <Item
                    f
                    url="/static/svg/danger-f.svg"
                    text={<FormattedMessage id="danger.f" />}
                />
                <Item
                    large
                    dna
                    url="/static/svg/danger-dna.svg"
                    text={<FormattedMessage id="danger.dna" />}
                />
                <Item
                    blue
                    url="/static/svg/danger-blue.svg"
                    text={<FormattedMessage id="danger.blue" />}
                />
            </div>
        </div>
    );
}
