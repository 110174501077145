import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="amsler" id="amsler">
        <div className="amsler-header__text">
            <FormattedMessage id="amsler.header" />
        </div>
        <div className="amsler-content__list">
            <ol>
                <li><FormattedMessage id="amsler.item.1" /></li>
                <li><FormattedMessage id="amsler.item.2" /></li>
                <li><FormattedMessage id="amsler.item.3" /></li>
                <li><FormattedMessage id="amsler.item.4" /></li>
                <li><FormattedMessage id="amsler.item.5" /></li>
                <li><FormattedMessage id="amsler.item.6" /></li>
                <li><FormattedMessage id="amsler.item.7" /></li>
            </ol>
        </div>
        <div className="amsler-content">
            <img src="/static/svg/amsler-img--lg.svg" alt="" />
            <div className="amsler-content--row">
                <div className="amsler-content--row__img">
                    <img src="/static/svg/amsler-img--warped.svg" alt="" />
                </div>
                <div className="amsler-content--row__text">
                    <FormattedMessage id="amsler.item.warp" />
                </div>
                <div className="amsler-content--row__img">
                    <img src="/static/svg/amsler-img--black.svg" alt="" />
                </div>
            </div>
        </div>
        <div className="amsler-nb">
            <FormattedMessage id="amsler.item.nb" />
        </div>
    </div>
));
