import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({logo, text, right}) => {
    const classes = cx({
        'recommend-item': true,
        'recommend-item--right': right
    });
    return (
        <div className={classes}>
            <img
                src={logo}
            />
            <div className="recommend-item__text">
                {text}
            </div>
        </div>
    );
}