import React from 'react';
import './HeaderText.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => {
    return(
        <div className="challenges-header">
            <div className="challenges-header__h">
                <FormattedMessage id="challenges.header.block.h" />
            </div>
            <div 
                className="challenges-header__t"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.header.block.t'})}}            
            />
        </div>
    );
})

