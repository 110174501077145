import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, text}) => {
        const classes = cx({
                'zinc-item': true
        });
        return (
            <div className={classes}>
                <div className="zinc-item__header">
                        {header}
                </div>
                <img
                        className="zinc-item__img"
                        src={url}
                />
            </div>
        );
}
