import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, text, large, age, weight, f, dna, blue}) => {
        const classes = cx({
                'danger-item': true,
                'danger-item--large': large,
                'danger-item--age': age,
                'danger-item--weight': weight,
                'danger-item--f': f,
                'danger-item--dna': dna,
                'danger-item--blue': blue
        });
        return (
            <div className={classes}>
                    <img
                        className="danger-item__img"
                        src={url}
                    />
                    <div className="danger-item__header">
                            {header}
                    </div>
                    <div className="danger-item__text">
                            {text}
                    </div>
            </div>
        );
}
