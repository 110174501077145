import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="signs">
        <div className="signs__header">
            <FormattedMessage id="signs.header" />
        </div>
        <div className="signs__text">
            <FormattedMessage id="signs.top.text" />
        </div>
        <div className="signs__items">
            <Item
                url="/static/images/signs-normal.png"
                header={<FormattedMessage id="signs.normal" />}
            />
            <Item
                url="/static/images/signs-slight.png"
                header={<FormattedMessage id="signs.slight" />}
            />
            <Item
                url="/static/images/signs-strong.png"
                header={<FormattedMessage id="signs.strong" />}
            />
        </div>
        <div className="signs_bottom">
            <div className="signs__text">
                <FormattedMessage id="signs.bottom.1" />
            </div>
            <div className="signs__point">
                <img src="/static/svg/signs-point.svg" alt="" />
            </div>
            <div className="signs__text">
                <FormattedMessage id="signs.bottom.2" />
            </div>
            <div className="signs__point">
                <img src="/static/svg/signs-point.svg" alt="" />
            </div>
            <div className="signs__text">
                <FormattedMessage id="signs.bottom.3" />
            </div>
            <div className="signs__point">
                <img src="/static/svg/signs-point.svg" alt="" />
            </div>
            <div className="signs__text">
                <FormattedMessage id="signs.bottom.4" />
            </div>
        </div>
    </div>
);
