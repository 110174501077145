import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'daily-intake-content__graph__text': true,
        'daily-intake-content__graph__text--red': true,
        'daily-intake-content__graph__text--red--ru': locale === 'ru'
    });
    return (
        <div className="daily-intake" id="daily-intake">
            <div className="daily-intake-header__text">
                <FormattedMessage id="daily.intake.header" />
            </div>
            <div className="daily-intake-content">
                <div className="daily-intake-content__image">
                    <img src="/static/images/daily-intake-fruit.png" alt="" />
                </div>
                <div className="daily-intake-content__graph">
                    <img src="/static/svg/daily-intake-graph.svg" alt="" />
                        <div 
                            className={classes}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.red.text'})}}
                        />
                        <div 
                            className="daily-intake-content__graph__text daily-intake-content__graph__text--figure--red"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.red'})}}
                        />
                        <div 
                            className="daily-intake-content__graph__text daily-intake-content__graph__text--figure--green"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.green'})}}
                        />
                </div>
                <div className="daily-intake-content__items">
                    <div className="daily-intake-content__item">
                        <img
                            src="/static/svg/daily-intake-red.svg"
                        />
                        <div 
                            className="daily-intake-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.right.red'})}}
                        />
                    </div>
                    <div className="daily-intake-content__item">
                        <img
                            src="/static/svg/daily-intake-green.svg"
                        />
                        <div 
                            className="daily-intake-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.right.green'})}}
                        />
                    </div>
                </div>
            </div>
            <div 
                className="daily-intake-footer"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'daily.intake.footer'})}}
            />
        </div>
    );
})
