import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="recommend-header__text">
        <FormattedMessage id="recommend.header" />
    </div>
);

