import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes_banner = cx({
        'top-banner': true,
        'top-banner--ru': locale === 'ru'
    });
    const classes = cx({
        'top-banner__text': true,
        'top-banner__text--ru': locale === 'ru'
    });
    const gradient_classes = cx({
        'top-banner__bottom-gradient': true,
        'top-banner__bottom-gradient--ru': locale === 'ru'
    });
    const btl_classes = cx({
        'top-banner__image-bottle': true,
        'top-banner__image-bottle--ru': locale === 'ru'
    });
    return (
        <div className={classes_banner}>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/375.png"
                />
                <source 
                    media="(max-width: 900px)"
                    srcSet="/static/images/top-banner--mobile.png"
                />
                <img 
                    className="top-banner__image"
                    alt=""
                    src="/static/images/top-banner.png"
                />
            </picture>
            <img
                src="/static/svg/banner-title.svg"
                alt=""
                className="top-banner__logo"
            />
            <div
                className={gradient_classes}
            >
                <div className={classes}>
                    <FormattedMessage id="top.banner.text" />
                </div>
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/top-banner-bottle--375.png"
                    />
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/images/top-banner-bottle--mobile.png"
                    />
                    <img
                        src="/static/images/top-banner-bottle.png"
                        alt=""
                        className={btl_classes}
                    />
                </picture>
            </div>
            
        </div>
    );
}
