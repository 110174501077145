import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'facts__wrap': true,
        'facts__wrap--ru': locale === 'ru'
    });
    const classes_left = cx({
        'facts-img--left': true,
        'facts-img--left--ru': locale === 'ru'
    });
    const classes_right = cx({
        'facts-img--right': true,
        'facts-img--right--ru': locale === 'ru'
    });
    const classes_bottom = cx({
        'facts-img--bottom': true,
        'facts-img--bottom--ru': locale === 'ru'
    });
    const classes_img = cx({
        'facts__image--wrap': true,
        'facts__image--wrap--ru': locale === 'ru'
    });
    return (
        <div className="facts" id="facts">
            <div className="facts-header__text">
                <FormattedMessage id="facts.header" />
            </div>
            <div className={classes}>
                <div className="facts-content">
                    <div className="facts-content__item facts-content__item--first">
                        <img
                            src="/static/svg/facts-point.svg"
                        />
                        <div className="facts-content__text">
                            <FormattedMessage id="facts.first.point" />
                        </div>
                    </div>
                    <div className="facts-content__item">
                        <img
                            src="/static/svg/facts-point.svg"
                        />
                        <div className="facts-content__text">
                            <FormattedMessage id="facts.second.point" />
                        </div>
                    </div>
                    <div className="facts-content__item">
                        <img
                            src="/static/svg/facts-point.svg"
                        />
                        <div 
                            className="facts-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'facts.third.point'})}}
                        />
                    </div>
                </div>
            </div>
            <div className={classes_left}>
                <picture>
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/svg/facts-line-left--mobile.svg"
                    />
                    <img
                        className="facts-img-line--left"
                        alt=""
                        src="/static/svg/facts-line-left.svg"
                    />
                </picture>
                <div className="facts-img__txt">
                    <FormattedMessage id="facts.image.left" />
                </div>
            </div>
            <div className={classes_right}>
                <picture>
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/svg/facts-line-right--mobile.svg"
                    />
                    <img
                        className="facts-img-line--right"
                        alt=""
                        src="/static/svg/facts-line-right.svg"
                    />
                </picture>
                <div className="facts-img__txt">
                    <FormattedMessage id="facts.image.right" />
                </div>            
            </div>
            <div className={classes_bottom}>
                <picture>
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/svg/facts-line-bottom--mobile.svg"
                    />
                    <img
                        className="facts-img-line--bottom"
                        alt=""
                        src="/static/svg/facts-line-bottom.svg"
                    />
                </picture>
                <div 
                    className="facts-img__txt"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'facts.image.bottom'})}}        
                />
            </div>
            <div className={classes_img}>
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/facts-bg--375.png"
                    />
                    <source 
                        media="(max-width: 900px)"
                        srcSet="/static/images/facts-bg--mobile.png"
                    />
                    <img
                        className="facts__image"
                        alt=""
                        src="/static/images/facts-bg.png"
                    />
                </picture>
            </div>
        </div>
    );
})
