import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ProtectEyes from './components/sections/protect-eyes';
import Facts from './components/sections/facts';
import Challenges from './components/sections/challenges';
import Danger from './components/sections/danger';
import Signs from './components/sections/signs';
import Zeaxanthin from './components/sections/zeaxanthin';
import DailyIntake from './components/sections/daily-intake';
import Zinc from './components/sections/zinc';
import Ginkgo from './components/sections/ginkgo';
import Amsler from './components/sections/amsler';
import Recommend from './components/sections/recommend';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <ProtectEyes/>
                    <Facts locale={locale} />
                    <Challenges locale={locale} />
                    <Danger locale={locale} />
                    <Signs/>
                    <Zeaxanthin locale={locale} />
                    <DailyIntake locale={locale} />
                    <Zinc/>
                    <Ginkgo/>
                    <Amsler/>
                    <Recommend/>
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer locale={locale}/>
                </div>
            </IntlProvider>
        );
    }
}

export default Page;
