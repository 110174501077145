import React from 'react';
import Item from './Item';
import './index.css';
import HeaderText from './HeaderText';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'challenges__items--top--second': true,
        'challenges__items--top--second--ru': locale === 'ru'
    });
    const classes_top = cx({
        'challenges__items--top': true,
        'challenges__items--top--ru': locale === 'ru'
    });
    return (
        <div className="challenges" id="challenges">
            <div className="challenges__header">
                <FormattedMessage id="challenges.header" />
            </div>
            <HeaderText />
            <div className="challenges__items">
                <div className="challenges__items--left">
                    <Item
                        url="/static/images/challenges-stress.png"
                    >
                        <div>
                            <FormattedMessage id="challenges.stress" />
                        </div>
                    </Item>
                    <Item
                        url="/static/images/challenges-food.png"
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.food'})}}
                        />
                    </Item>
                </div>
                <div className="challenges__items--middle">
                    <Item
                        large
                        url="/static/images/challenges-middle.png"
                        >
                    </Item>
                    <Item
                        large
                        url="/static/images/challenges-screens.png"
                        >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.screens'})}}
                        />
                    </Item>
                </div>
                <div className="challenges__items--right">
                    <Item
                        url="/static/images/challenges-smoking.png"
                    >
                        <div>
                            <FormattedMessage id="challenges.smoking" />
                        </div>
                    </Item>
                    <Item
                        url="/static/images/challenges-cars.png"
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.cars'})}}
                        />
                    </Item>
                </div>
            </div>

            <div className="challenges__items--mobile">
                <div className={classes_top}>
                    <Item
                        locale={locale}
                        url="/static/images/challenges-cars.png"
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.cars'})}}
                        />
                    </Item>
                </div>
                <div className={classes}>
                    <Item
                        locale={locale}
                        left
                        url="/static/images/challenges-stress.png"
                    >
                        <div>
                            <FormattedMessage id="challenges.stress" />
                        </div>
                    </Item>
                    <Item
                        locale={locale}
                        right
                        url="/static/images/challenges-smoking.png"
                    >
                        <div>
                            <FormattedMessage id="challenges.smoking" />
                        </div>
                    </Item>
                </div>
                <div className="challenges__items--middle--mobile">
                    <Item
                        localelarge={locale}
                        large
                        url="/static/images/challenges-middle.png"
                        >
                    </Item>
                </div>
                <div className="challenges__items--bottom">
                    <Item
                        locale={locale}
                        left
                        url="/static/images/challenges-food.png"
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.food'})}}
                        />
                    </Item>
                    <Item
                        locale={locale}
                        right
                        url="/static/images/challenges-screens.png"
                        >
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'challenges.screens'})}}
                        />
                    </Item>
                </div>
            </div>
        </div>
    );
})
