import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="zinc" id="zinc">
        <div className="zinc-header__text">
            <FormattedMessage id="zinc.header" />
        </div>
        <div className="zinc-content">
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.1'})}}
                />
            </div>
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.2'})}}
                />
            </div>
            <div className="zinc-content__item">
                <img
                    src="/static/svg/zinc-point.svg"
                />
                <div 
                    className="zinc-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zinc.item.3'})}}
                />
            </div>
            <div className="zinc-content__images">
                <Item
                    url="/static/images/zinc-oyster.png"
                    header={<FormattedMessage id="zinc.oyster" />}
                />
                <Item
                    url="/static/images/zinc-beef.png"
                    header={<FormattedMessage id="zinc.beef" />}
                />
                <Item
                    url="/static/images/zinc-cheese.png"
                    header={<FormattedMessage id="zinc.cheese" />}
                />
            </div>
        </div>
    </div>
));
