import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'zeaxanthin-content__image__text': true,
        'zeaxanthin-content__image__text--pigment': true,
        'zeaxanthin-content__image__text--pigment--ru': locale === 'ru'
    });
    return (
        <div className="zeaxanthin" id="zeaxanthin">
            <div className="zeaxanthin-header__text">
                <FormattedMessage id="zeaxanthin.header" />
            </div>
            <div className="zeaxanthin-content">
                <div className="zeaxanthin-content__item">
                    <img
                        src="/static/svg/zeaxanthin-point.svg"
                    />
                    <div 
                        className="zeaxanthin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zeaxanthin.item.1'})}}
                    />
                </div>
                <div className="zeaxanthin-content__item">
                    <img
                        src="/static/svg/zeaxanthin-point.svg"
                    />
                    <div 
                        className="zeaxanthin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zeaxanthin.item.2'})}}
                    />
                </div>
                <div className="zeaxanthin-content__image">
                    <img src="/static/images/zeaxanthin-img.png" alt="" />
                    <div className="zeaxanthin-content__image__text zeaxanthin-content__image__text--light">
                        <FormattedMessage id="zeaxanthin.image.light" />
                    </div>
                    <div className="zeaxanthin-content__image__text zeaxanthin-content__image__text--lens">
                        <FormattedMessage id="zeaxanthin.image.lens" />
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="zeaxanthin.image.pigment" />
                    </div>
                </div>
                <div className="zeaxanthin-content__image--mobile">
                    <div className="zeaxanthin-content__image--mobile__container">
                        <img src="/static/svg/zeaxanthin-content__image--light.svg" alt="" />
                        <div className="zeaxanthin-content__image__text zeaxanthin-content__image__text--light">
                            <FormattedMessage id="zeaxanthin.image.light" />
                        </div>
                    </div>
                    <img 
                        className="zeaxanthin-content__image--mobile--molecule" 
                        src="/static/svg/zeaxanthin-content__image--molecule.svg" 
                        alt="" 
                    />
                    <div className="zeaxanthin-content__image--mobile__container">
                        <img src="/static/svg/zeaxanthin-content__image--eye.svg" alt="" />
                        <div className="zeaxanthin-content__image__text zeaxanthin-content__image__text--lens">
                            <FormattedMessage id="zeaxanthin.image.lens" />
                        </div>
                        <div className={classes}>
                            <FormattedMessage id="zeaxanthin.image.pigment" />
                        </div>
                    </div>
                </div>
                <div className="zeaxanthin-content__item">
                    <img
                        src="/static/svg/zeaxanthin-point.svg"
                    />
                    <div 
                        className="zeaxanthin-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zeaxanthin.item.3'})}}
                    />
                </div>
            </div>
        </div>
    );
})
