import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, children, large, left, right, locale, localelarge}) => {
        const classes = cx({
            'challenges-item': true,
            'challenges-item--large': large,
            'challenges-item--left': left,
            'challenges-item--right': right,
            'challenges-item--large--ru': localelarge === 'ru'
        });
        const classes_img = cx({
            'challenges-item__img': true,
            'challenges-item__img--ru': locale === 'ru'
        });
        return (
            <div className={classes}>
                <div className="challenges-item__container">
                    <img
                        className={classes_img}
                        src={url}
                    />
                    <div className="challenges-item__header">
                            {header}
                    </div>
                    <div className="challenges-item__text">
                            {children}
                    </div>
                </div>
            </div>
        );
}
