import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="ginkgo" id="ginkgo">
        <div className="ginkgo-banner">
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/ginkgo--375.png"
                />
                <img
                    src="/static/images/ginkgo.png"
                    alt=""
                    className="ginkgo-banner__image"
                />
            </picture>
        </div>
        <div className="ginkgo-header__text">
            <FormattedMessage id="ginkgo.header" />
        </div>
        <div className="ginkgo-content">
            <div className="ginkgo-content__item">
                <img
                    src="/static/svg/ginkgo-point.svg"
                />
                <div 
                    className="ginkgo-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ginkgo.item.1'})}}
                />
            </div>
            <div className="ginkgo-content__item">
                <img
                    src="/static/svg/ginkgo-point.svg"
                />
                <div 
                    className="ginkgo-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ginkgo.item.2'})}}
                />
            </div>
            <div className="ginkgo-content__item">
                <img
                    src="/static/svg/ginkgo-point.svg"
                />
                <div 
                    className="ginkgo-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ginkgo.item.3'})}}
                />
            </div>
        </div>
        <div className="ginkgo-footer">
            <div className="ginkgo-footer__bulb">
                <img src="/static/svg/ginkgo-bulb.svg" alt="" />
                <div className="ginkgo-footer__bulb__text">
                    <FormattedMessage id="ginkgo.bulb" />
                </div>
            </div>
            <div
                className="ginkgo-footer__text"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ginkgo.footer'})}}
            />
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/ginkgo-leaf--375.png"
                />
                <img
                    src="/static/images/ginkgo-leaf.png"
                    alt=""
                    className="ginkgo-footer__leaf"
                />
            </picture>
        </div>
    </div>
));
